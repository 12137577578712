import { Box, Button, styled } from '@mui/material';

export const NotificationsBox = styled(Box)(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing(4)} ${theme.spacing(6)};
  padding-right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: ${theme.palette.common.white};

  & .dialog-scroll {
    overflow-y: auto;

    .dialog-body {
      padding-right: ${theme.spacing(6)};
    }
  }
`,
);

export const MarkReadButton = styled(Button)`
  padding: 0;
  text-transform: none;
`;

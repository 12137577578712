import React from 'react';
import { MenuItem, Stack, useTheme } from '@mui/material';
import Link from 'next/link';
import Icon from '@/components/icons/Icon';

import { MenuItemType } from './helpers';
import { Counter } from './NavbarVillageLink.styles';

export enum ItemSizes {
  small = 1,
  medium,
  large,
}

const fontSizes = {
  [ItemSizes.large]: '1.2rem',
  [ItemSizes.medium]: '1rem',
  [ItemSizes.small]: '0.9rem',
};

type NavbarVillageLinkMenuItemProps = {
  item: MenuItemType;
  togglePopper: () => void;
  size?: ItemSizes;
};

const NavbarVillageLinkMenuItem = ({
  item,
  togglePopper,
  size = ItemSizes.medium,
}: NavbarVillageLinkMenuItemProps) => {
  const theme = useTheme();
  const { iconName, counter } = item;

  const fontSize = fontSizes[size];

  const menuItem = (
    <MenuItem
      component="a"
      onClick={() => {
        item.onClick?.();
        togglePopper();
      }}
      sx={{ px: { xs: theme.spacing(6), sm: theme.spacing(4) } }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        {!!iconName && (
          <Icon
            iconName={iconName}
            style={{ color: theme.palette.grey[500], fontSize }}
          />
        )}
        <span style={{ fontSize }}>{item.node || item.label}</span>
        <div style={{ flex: 1 }} />
        {!!counter && (
          <Counter color="error" badgeContent={counter} invisible={!counter} />
        )}
      </Stack>
    </MenuItem>
  );

  return item.path ? (
    <Link href={item.path} passHref>
      {menuItem}
    </Link>
  ) : (
    menuItem
  );
};

export default NavbarVillageLinkMenuItem;

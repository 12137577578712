import styled from '@emotion/styled';
import xw from 'xwind';

interface SocialLinkProps {
  src: string;
  href: string;
  alt: string;
}

const StyledSocialLink = styled.a(xw`mr-3 sm:mr-8 last:mr-0`);

function SocialLink({ src, href, alt }: SocialLinkProps): JSX.Element {
  return (
    <StyledSocialLink href={href} target="_blank" rel="noopener noreferrer">
      <img src={src} css={xw`w-5 sm:w-6`} alt={alt} />
    </StyledSocialLink>
  );
}

export default SocialLink;

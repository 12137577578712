import { useState, useCallback, useEffect } from 'react';
import { useMutationObserver } from './useMutationObserver';

const containerSelector = 'body > #intercom-container';
const bannerSelector = 'iframe[name=intercom-banner-frame]';

export function useIntercomHeight(): number {
  const [targetSelector, setTargetSelector] = useState<string>(null);
  const [bannerElement, setBannerElement] = useState<HTMLBodyElement>(null);
  const [totalHeight, setTotalHeight] = useState(0);

  const bodyMutationCallback = useCallback<MutationCallback>(
    (mutationList, observer) => {
      mutationList.forEach((mutation) => {
        if (mutation.type !== 'childList') return;
        const isAddition = mutation.addedNodes.length;

        if (isAddition) {
          mutation.addedNodes.forEach((node) => {
            const isElement = node instanceof HTMLElement;

            if (!isElement) return;

            if (document.querySelector(containerSelector)) {
              observer.disconnect();
              setTargetSelector(containerSelector);
            }
          });
        }
      });
    },
    [setTargetSelector],
  );

  const containerMutationCallback = useCallback<MutationCallback>(
    (mutationList, observer) => {
      mutationList.forEach((mutation) => {
        if (mutation.type !== 'childList') return;
        const isAddition = mutation.addedNodes.length;
        const isRemotion = mutation.removedNodes.length;

        if (isAddition) {
          mutation.addedNodes.forEach((node) => {
            const isElement = node instanceof HTMLElement;

            if (!isElement) return;
            const banner = document.querySelector(
              bannerSelector,
            ) as HTMLIFrameElement;
            if (banner) {
              setBannerElement(banner.contentDocument.body as HTMLBodyElement);
            }
          });
        }
        if (isRemotion) {
          setTotalHeight(0);
          observer.disconnect();
          setBannerElement(null);
        }
      });
    },
    [setBannerElement],
  );

  useMutationObserver('body', bodyMutationCallback);

  useMutationObserver(targetSelector, containerMutationCallback, {
    childList: true,
    subtree: true,
  });

  useEffect(() => {
    let resizeObserver: ResizeObserver = null;
    if (bannerElement && process.browser) {
      resizeObserver = new ResizeObserver(([entrie]: ResizeObserverEntry[]) => {
        const height = entrie?.contentRect?.height;
        const margin =
          parseInt(window?.getComputedStyle(entrie.target)?.margin, 10) * 2;
        if (height) {
          setTotalHeight(height + margin);
        }
      });
      resizeObserver.observe(bannerElement);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [bannerElement, setTotalHeight]);

  return totalHeight;
}

export default useIntercomHeight;

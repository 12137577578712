import React, { MouseEvent, MouseEventHandler, useMemo } from 'react';
import Link from 'next/link';
import { IconMenuButton } from '@/widgets/PageHeader/PageHeader.styles';
import Icon from '@/components/icons/Icon';
import { useRouter } from 'next/router';
import { loggedActionsGuard } from '@/utils/loggedActionsGuard';
import AuthStore from '@/stores/AuthStore';
import TutorialStore, { getTutorialStepId } from '@/stores/TutorialStore';
import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import xw from 'xwind';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { observer } from 'mobx-react-lite';

export type THeaderNavbarItem = {
  id?: string;
  path?: string;
  activeForPaths?: string[];
  iconName?: string;
  surveyNeeded?: boolean;
  checkActive?: boolean;
  strictPath?: boolean;
  label?: string | JSX.Element;
  children?: string | JSX.Element;
  visible?: boolean;
  title?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  emptyBox?: boolean;
  sx?: SxProps;
  mobileTabbar?: boolean;
  isAdminOrOwnerUser?: boolean;
};

const HeaderNavbarItem = ({
  id,
  path,
  iconName,
  surveyNeeded,
  strictPath,
  checkActive = true,
  label,
  children,
  visible = true,
  title,
  onClick = () => {},
  emptyBox,
  sx,
  mobileTabbar = false,
  isAdminOrOwnerUser = false,
  activeForPaths,
}: THeaderNavbarItem): JSX.Element => {
  const router = useRouter();
  const theme = useTheme();
  const { isLoggedIn } = AuthStore;
  const { isMustCreateVillage } = FridgeDoorStore;
  const { isTutorialActive, currentStep } = TutorialStore;
  const isUnauthorized = surveyNeeded && (isMustCreateVillage || !isLoggedIn);

  let active = useMemo(
    () =>
      checkActive &&
      (strictPath
        ? router.asPath === path
        : router.asPath.includes(path) ||
          activeForPaths?.includes(router.asPath)),
    [checkActive, path, router.asPath, strictPath, activeForPaths],
  );

  if (!visible) return null;
  if (emptyBox) return <Box sx={sx} />;

  if (isTutorialActive) {
    active = active || getTutorialStepId(currentStep) === id;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleUnauthorizedClick: MouseEventHandler = (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    handleClick(e);
    const promptModal = loggedActionsGuard(
      isLoggedIn,
      isMustCreateVillage,
      () => {},
    ) as () => void;
    promptModal();
  };

  const itemLabel = (
    <Stack
      direction={mobileTabbar ? 'column' : 'row'}
      spacing={mobileTabbar ? 1 : isAdminOrOwnerUser ? 1.5 : 2}
      alignItems="center"
      justifyContent="center"
      sx={{ padding: mobileTabbar ? theme.spacing(0, 1) : 0 }}
    >
      {!!iconName && (
        <Icon
          iconName={iconName}
          style={{
            ...(active ? { color: theme.palette.warning[800] } : undefined),
            ...(mobileTabbar && { fontSize: theme.spacing(6) }),
          }}
        />
      )}
      {!!label && (
        <div
          style={{
            fontSize: mobileTabbar ? '0.625rem' : '1rem',
            lineHeight: 1.25,
          }}
        >
          {label}
        </div>
      )}
    </Stack>
  );

  if (isUnauthorized) {
    return (
      <IconMenuButton
        id={id}
        variant={active ? 'contained' : 'text'}
        color={active ? 'warning' : 'inherit'}
        title={title}
        onClick={handleUnauthorizedClick}
        sx={sx}
        mobileTabbar={mobileTabbar}
      >
        {itemLabel}
        {children}
      </IconMenuButton>
    );
  }

  const iconButton = (
    <IconMenuButton
      id={id}
      variant={active ? 'contained' : 'text'}
      color={active ? 'warning' : 'inherit'}
      title={title}
      component="a"
      onClick={handleClick}
      sx={sx}
      mobileTabbar={mobileTabbar}
    >
      {itemLabel}
      {children}
    </IconMenuButton>
  );

  return path ? <Link href={path}>{iconButton}</Link> : iconButton;
};

export default observer(HeaderNavbarItem);

import { styled } from '@mui/material';

export const StyledLogoDescription = styled('a')`
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 14px;
    margin-top: 1px;
  }
`;

export const StyledLogoContainer = styled('a')`
  &.logoContainer {
    position: relative;
    display: block;
    width: 90%;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;
  }
`;

export const StyledLogo = styled('div')`
  width: 100%;
`;

import { ORG_PATH } from '@/common/constants/routes';
import { MenuItemType } from '@/domains/Village/components/AsideContent/helpers';
import { Organization } from '@/types/Content/Org';

export const getMenuItems = (
  adminAccess?: Organization['admin_access'],
  isDesktop?: boolean,
): MenuItemType[] => {
  const result: MenuItemType[] = [
    {
      iconName: 'feed',
      label: 'Dashboard',
      path: ORG_PATH,
    },
    {
      iconName: 'calendar',
      label: 'Events',
      path: `${ORG_PATH}/events`,
    },
    {
      iconName: 'document',
      label: 'Documents',
      path: `${ORG_PATH}/documents`,
    },

    // {
    //   iconName: 'multiple-comments',
    //   label: 'Contact',
    //   path: `${ORG_PATH}/contact`,
    // },
  ];

  if (adminAccess?.includes('employee')) {
    result.push({
      iconName: 'users',
      label: isDesktop ? 'HR Management' : 'Management',
      path: `${ORG_PATH}/hr`,
    });
  }

  result.push({
    iconName: 'briefcase',
    label: isDesktop ? 'Billing and Payments' : 'Billing',
    path: `${ORG_PATH}/billing`,
  });

  return result;
};

import xw from 'xwind';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';

export const MenuContainer = styled.nav(
  xw`flex flex-col xl[flex-row items-center]`,
);

export const MenuItem = styled.a`
  ${xw`
    text-base sm:text-sm text-white
    mb-3 xl[mb-0 mr-6 text-base] last[mb-0 mr-0] cursor-pointer`}
`;

export const SocialMediaContainer = styled.div`
  ${xw`
    flex flex-row justify-center items-baseline my-auto
    xl[justify-end my-0]
  `}
`;

export const FooterDivider = styled(Divider)`
  ${xw`md:hidden`}
`;

export const FooterInfoText = styled.p(
  `
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;

  display: flex;
  align-items: center;
  letter-spacing: 0.58px;

  color: #91c7cb;
`,
  xw`lg[text-xs]`,
);

import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { format, isSameDay } from 'date-fns';
import xw from 'xwind';

import Icon from '@/components/icons/Icon';
import LoadingIndicator from '@/components/LoadingIndicator';
import { IFormModalProps } from '@/widgets/Modal/FormModal';
import NotificationsStore from '@/stores/NotificationsStore';

import NotificationItem from './components/Notification/Notification';
import { MarkReadButton, NotificationsBox } from './Notifications.styles';

const Notifications: React.FC<IFormModalProps> = ({ onClose }) => {
  const {
    notifications,
    isLoading,
    currentPage,
    hasNextPage,
  } = NotificationsStore;

  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    loading: isLoading,
    onLoadMore: () =>
      NotificationsStore.fetchNotifications({
        page: currentPage + 1,
      }),
    rootMargin: '0px 0px 200px 0px',
  });

  useEffect(() => {
    NotificationsStore.fetchNotifications({ page: 1 });

    return () => {
      NotificationsStore.resetList();
    };
  }, []);

  const onMarkAllRead = () => {
    NotificationsStore.readAllNotifications();
  };

  return (
    <NotificationsBox>
      <Grid container justifyContent="space-between" alignItems="center" pr={4}>
        <Typography variant="h3">Notifications</Typography>
        <Button
          variant="text"
          color="inherit"
          sx={xw`top-0 right-0 min-w-0 sm[text-base p-2.5]`}
          onClick={(e) => onClose(e, 'escapeKeyDown')}
        >
          <Icon iconName="times" />
        </Button>
      </Grid>

      <Grid className="dialog-scroll">
        <Grid className="dialog-body">
          {notifications?.length
            ? notifications.map((item, i) => {
                const notificDate = new Date(item.created_at);
                const isFirst = !i;

                const hasDayChanged =
                  isFirst ||
                  !isSameDay(
                    notificDate,
                    new Date(notifications[i - 1]?.created_at),
                  );

                return (
                  <Grid key={item.id}>
                    {hasDayChanged && (
                      <Grid
                        container
                        justifyContent="space-between"
                        mt={5}
                        mb={4}
                      >
                        <Typography variant="h5" color="grey.350">
                          {format(notificDate, 'MMM d')}
                        </Typography>

                        {isFirst && (
                          <MarkReadButton
                            variant="text"
                            onClick={onMarkAllRead}
                          >
                            Mark all as read
                          </MarkReadButton>
                        )}
                      </Grid>
                    )}

                    <NotificationItem notification={item} />
                  </Grid>
                );
              })
            : !isLoading && (
                <Grid container justifyContent="center" pb={4} pt={3}>
                  You have no notifications
                </Grid>
              )}

          {(isLoading || hasNextPage) && (
            <Grid
              ref={sentryRef}
              container
              justifyContent="center"
              pb={4}
              pt={3}
            >
              <LoadingIndicator />
            </Grid>
          )}
        </Grid>
      </Grid>
    </NotificationsBox>
  );
};

export default observer(Notifications);

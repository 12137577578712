import {
  forwardRef,
  MouseEventHandler,
  AllHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { Autocomplete as MUIAutocomplete, Button } from '@mui/material';

import LoadingIndicator from '@/components/LoadingIndicator';
import Icon from '@/components/icons/Icon';
import {
  Adornment,
  InputComponent,
  List,
  LoadingComponent,
  Popper,
} from './Autocomplete.styles';
import { AutocompletePropTypes } from './Autocomplete.types';
import { SearchIcon } from '../icons/SearchIcon';

export const Autocomplete = forwardRef(
  (
    {
      label,
      loading,
      options,
      onSearch,
      onChange,
      onFocus,
      onBlur,
      isFocused,
      noOptionsText,
      getOptionLabel,
      filterOptions,
      groupBy,
      className,
      renderInput,
      PopperComponent,
    }: AutocompletePropTypes,
    ref,
  ) => {
    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(new Date().toISOString());

    useEffect(() => {
      if (isFocused && inputKey) {
        inputRef.current?.focus();
      }
    }, [isFocused, inputKey]);

    const handleChange = useCallback(
      debounce((event, details) => {
        onSearch(event, details);
      }, 300),
      [onSearch],
    );

    const handleClear: MouseEventHandler = (e) => {
      e.stopPropagation();

      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setInputKey(new Date().toISOString());
    };

    return (
      <MUIAutocomplete
        ref={ref}
        key={inputKey}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
        openOnFocus={false}
        filterOptions={(opt) => opt}
        filterSelectedOptions={false}
        autoHighlight
        getOptionLabel={getOptionLabel}
        groupBy={groupBy}
        disableClearable
        clearOnBlur={false}
        fullWidth
        loading={loading}
        popupIcon={null}
        PaperComponent={Popper}
        ListboxComponent={List}
        onInputChange={handleChange}
        noOptionsText={<LoadingComponent>{noOptionsText}</LoadingComponent>}
        loadingText={
          <LoadingComponent>
            {' '}
            Searching... <LoadingIndicator />{' '}
          </LoadingComponent>
        }
        PopperComponent={PopperComponent}
        renderInput={
          renderInput
            ? (params) => renderInput(params)
            : (params) => (
                <InputComponent
                  {...params}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Adornment position="start">
                        <SearchIcon width={20} height={20} />
                      </Adornment>
                    ),
                    endAdornment: (
                      params.inputProps as AllHTMLAttributes<HTMLInputElement>
                    )?.value && (
                      <Adornment position="end">
                        <Button
                          variant="text"
                          color="inherit"
                          onClick={handleClear}
                        >
                          <Icon iconName="cross" />
                        </Button>
                      </Adornment>
                    ),
                    type: 'search',
                  }}
                  open={
                    (params?.InputProps?.endAdornment as any)?.props?.ownerState
                      ?.popupOpen
                  }
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: false,
                  }}
                  label={label}
                  fullWidth
                  hiddenLabel
                />
              )
        }
      />
    );
  },
);

Autocomplete.defaultProps = {
  options: [],
};

import React from 'react';
import { styled } from '@mui/material';

import CustomAvatar from '@/components/CustomAvatar';
import { ICmsUser } from '@/types/Content/User';
import Icon from '@/components/icons/Icon';
import { color } from '../../../../theme.material';

const ProfileWrapper = styled('div')<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.5, 2.5, 1.5, 1.5)};
  min-height: 100%;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.palette.primary[500] : theme.palette.grey[100]};
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.palette.grey[50] : theme.palette.common.black};
  border-radius: 1000px;
  transition: 0.2s;
  .icons {
    font-size: ${({ theme }) => theme.spacing(2)};
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));
    opacity: 0;
    transition: 0.2s opacity;
    border-radius: 1000px;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

type ProfilePropTypes = {
  cmsUser: ICmsUser;
  isOpen: boolean;
};

export function ProfileTitle({
  cmsUser,
  isOpen,
}: ProfilePropTypes): JSX.Element {
  return (
    <ProfileWrapper isOpen={isOpen}>
      <CustomAvatar
        image={cmsUser?.image?.url}
        name={cmsUser?.firstName}
        size={{ desktop: 8, mobile: 8 }}
        fontSize={{ desktop: 4, mobile: 4 }}
        textColor={isOpen ? color.teal[500] : color.grey[50]}
        backgroundColor={isOpen ? color.grey[50] : color.teal[500]}
        isPointer={true}
      />
      <Icon iconName="caret-down" />
    </ProfileWrapper>
  );
}

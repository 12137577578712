import { styled } from '@mui/material';
import { Container } from '@/components/layout';

export const TabBar = styled('header')<{ top?: number }>`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 49;
`;

export const TabBarContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

import { HOME_PATH, SHOP_PATH, VILLAGE_PATH } from '@/common/constants/routes';
import { FridgeDoorVillageType } from '@/types/Village/FridgeDoorVillage';

export type MenuItemType = {
  iconName?: string;
  node?: string;
  label?: string;
  onClick?: () => void;
  path?: string;
  counter?: number;
};

export const getVillageMenuItems = ({
  currentVillage,
}: {
  currentVillage: FridgeDoorVillageType;
}): MenuItemType[] => {
  const getVillagePath = (path = '') =>
    currentVillage
      ? `${VILLAGE_PATH}/${currentVillage.slug}${path}`
      : HOME_PATH;

  return [
    {
      iconName: 'home',
      node: 'Dashboard',
      path: getVillagePath('/home'),
    },
    {
      iconName: 'calendar',
      node: 'Events',
      path: getVillagePath('/events'),
    },
    {
      iconName: 'shop',
      node: 'Shop',
      path: SHOP_PATH,
    },
    {
      iconName: 'wishlist',
      node: 'Wishlist',
      path: getVillagePath('/wishlist'),
    },
  ];
};

export const getOrgMenuItems = ({
  currentVillage,
}: {
  currentVillage: FridgeDoorVillageType;
}): MenuItemType[] => {
  const getVillagePath = (path = '') =>
    currentVillage
      ? `${VILLAGE_PATH}/${currentVillage.slug}${path}`
      : HOME_PATH;

  return [
    {
      iconName: 'home',
      node: 'Dashboard',
      path: getVillagePath('/home'),
    },
    {
      iconName: 'calendar',
      node: 'Events',
      path: getVillagePath('/events'),
    },
    {
      iconName: 'shop',
      node: 'Shop',
      path: SHOP_PATH,
    },
    {
      iconName: 'wishlist',
      node: 'Wishlist',
      path: getVillagePath('/wishlist'),
    },
  ];
};

export const getMenuItems = ({
  isOrganizationMember,
  logout,
  setIsNotificationsOpen,
  totalUnread,
}: {
  isOrganizationMember: boolean;
  logout: () => void;
  setIsNotificationsOpen: (val: boolean) => void;
  totalUnread: number;
}): MenuItemType[] => {
  const items: MenuItemType[] = [];

  if (!isOrganizationMember) {
    items.push(
      {
        node: 'Profile Settings',
        iconName: 'settings',
        path: '/settings/notifications',
      },
      {
        node: 'Notifications',
        iconName: 'bell',
        counter: totalUnread,
        onClick: () => {
          setIsNotificationsOpen(true);
        },
      },
    );
  }

  items.push({
    node: 'Log Out',
    iconName: 'logout',
    onClick: logout,
  });

  return items;
};

import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import xw from 'xwind';
import { useMediaQuery, useTheme } from '@mui/material';

import { AuthStore } from '@/stores/index';
import PageFooter from '../PageFooter';
import { PageHeader } from '../PageHeader/PageHeader';
import { PageHeaderCompany } from '../PageHeader/PageHeaderCompany';
import PageMobileTabBar from '../PageMobileTabBar/PageMobileTabBar';

interface LayoutProps {
  header?: ReactNode;
  footer?: ReactNode;
  showMobTabBar?: boolean;
  hideContent?: boolean;
}

function Layout({
  header,
  footer,
  showMobTabBar = true,
  hideContent = false,
  children,
}: React.PropsWithChildren<LayoutProps>): JSX.Element {
  const theme = useTheme();
  const showMobileTabBar = useMediaQuery(theme.breakpoints.down('lg'));

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const { isOrganizationMember } = AuthStore;

  return (
    <>
      {header ||
        (isOrganizationMember ? (
          <PageHeaderCompany />
        ) : (
          <PageHeader
            isSearchFocused={isSearchFocused}
            setIsSearchFocused={setIsSearchFocused}
            hideContent={hideContent}
          />
        ))}

      <main style={xw`flex flex-1 flex-col`}>{children}</main>

      {footer || <PageFooter />}

      {showMobileTabBar && showMobTabBar && (
        <PageMobileTabBar setIsSearchFocused={setIsSearchFocused} />
      )}
    </>
  );
}

export default observer(Layout);

import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  useTheme,
  Divider,
  useMediaQuery,
  Modal,
  Stack,
} from '@mui/material';

import AuthStore from '@/stores/AuthStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import NotificationsStore from '@/stores/NotificationsStore';
import Notifications from '@/domains/Notifications';
import useAuth from '@/hooks/useAuth';
import { stopPropagation } from '@/utils/common';

import { useRouter } from 'next/router';
import OrgStore from '@/stores/OrgStore';
import {
  NotificationsPopover,
  MenuPaper,
  MobileMenuHeaderContainer,
} from './NavbarVillageLink.styles';
import { getMenuItems, getVillageMenuItems, MenuItemType } from './helpers';
import { getMenuItems as getOrgMenuItems } from '../../AppPageLayout/OrgPageLayout/AsideContent/helpers';
import NavbarVillageLinkMenuItem, {
  ItemSizes,
} from './NavbarVillageLinkMenuItem';
import { CaretButton, VillageIcon, VillageName } from './helpersUI';

type Props = {
  open: boolean;
  isCurrentVillagePages: boolean;
  anchorRef: React.MutableRefObject<HTMLDivElement>;
  onClose: (event?: Event) => void;
  onToggle: () => void;
};

const NavbarVillageLinkMenu = ({
  open,
  isCurrentVillagePages,
  anchorRef,
  onClose,
  onToggle,
}: Props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useRouter();
  const isOrg = useMemo(() => pathname === '/org/[orgTab]', [pathname]);

  const { logout } = useAuth();
  const { org } = OrgStore;

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const closeNotifications = () => {
    setIsNotificationsOpen(false);
  };

  const { isOrganizationMember } = AuthStore;
  const { villages, currentVillage } = FridgeDoorStore;
  const { totalUnread } = NotificationsStore;

  const villageMenuItems = useMemo(
    () =>
      isOrg
        ? getOrgMenuItems(org?.admin_access, true)
        : getVillageMenuItems({ currentVillage }),
    [currentVillage, isOrg, org?.admin_access],
  );

  const menuItems = useMemo(
    () =>
      getMenuItems({
        isOrganizationMember,
        logout,
        setIsNotificationsOpen,
        totalUnread,
      }),
    [isOrganizationMember, logout, totalUnread],
  );

  const renderList = (list: MenuItemType[], defultSize = ItemSizes.medium) =>
    list.map((item) => (
      <NavbarVillageLinkMenuItem
        key={item.node + (item.path || '')}
        item={item}
        togglePopper={onToggle}
        size={isMobileView ? ItemSizes.large : defultSize}
      />
    ));

  const menuList = (
    <MenuList id="split-button-menu" autoFocusItem>
      {isMobileView && (
        <>
          {renderList(villageMenuItems)}
          <Divider variant="fullWidth" />
        </>
      )}

      {renderList(menuItems, ItemSizes.small)}
    </MenuList>
  );

  const content = (
    <MenuPaper
      style={
        isMobileView
          ? undefined
          : {
              borderTop: `1px solid ${
                isCurrentVillagePages ? '#A86300' : theme.palette.divider
              }`,
            }
      }
      elevation={3}
      onScroll={stopPropagation}
      onClick={stopPropagation}
      isMobileView
    >
      {isMobileView ? (
        <>
          <MobileMenuHeaderContainer
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={onToggle}
          >
            <Stack direction="row" alignItems="center" spacing={3}>
              <VillageIcon
                currentVillageFirstName={currentVillage?.first_name}
                totalUnread={totalUnread}
              />
              <VillageName
                currentVillageFirstName={currentVillage?.first_name}
                mobileView
              />
            </Stack>
            <CaretButton opened={open} />
          </MobileMenuHeaderContainer>
          {menuList}
        </>
      ) : (
        <ClickAwayListener onClickAway={onClose}>{menuList}</ClickAwayListener>
      )}
    </MenuPaper>
  );

  return (
    <>
      {isMobileView ? (
        <Modal open={open} onClose={onClose}>
          {content}
        </Modal>
      ) : (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom"
          transition
          disablePortal
          style={{
            width: anchorRef.current?.clientWidth,
            minWidth: theme.spacing(45),
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              {content}
            </Grow>
          )}
        </Popper>
      )}

      <NotificationsPopover
        id="notifications-popover"
        open={isNotificationsOpen}
        anchorEl={anchorRef.current}
        onClose={closeNotifications}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Notifications onClose={closeNotifications} />
      </NotificationsPopover>
    </>
  );
};

export default observer(NavbarVillageLinkMenu);

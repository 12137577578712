import { captureException } from '@sentry/nextjs';
import { useState, useEffect, useRef } from 'react';

const defaultOptions: MutationObserverInit = {
  childList: true,
  subtree: false,
  attributes: false,
};

export function useMutationObserver(
  querySelector: string,
  callback: MutationCallback,
  options: MutationObserverInit = defaultOptions,
): void {
  const targetRef = useRef<HTMLElement>(null);
  const [observer, setObserver] = useState<MutationObserver>(null);

  useEffect(() => {
    targetRef.current = document.querySelector(querySelector);
  }, [querySelector]);

  useEffect(() => {
    if (!window.MutationObserver) return;
    const newObserver = new MutationObserver(callback);
    setObserver(newObserver);
  }, [callback]);

  useEffect(() => {
    if (!observer || !targetRef.current) return null;

    try {
      observer.observe(targetRef.current, options);
    } catch (error) {
      captureException(error);
    }

    return () => observer && observer.disconnect();
  }, [observer, options]);
}

export default useMutationObserver;

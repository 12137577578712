import FridgeDoorStore from '@/stores/FridgeDoorStore';
import Image from 'next/image';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { HOME_PATH } from '@/common/constants/routes';

import { StyledLogo, StyledLogoContainer } from './index.styled';

const LogoPure = ({
  img = 'logo',
  width = '206',
}: {
  img?: string;
  width?: string;
}) => {
  const { currentVillagePath } = FridgeDoorStore;

  return (
    <StyledLogo>
      <Link
        href={currentVillagePath ? `${currentVillagePath}/home` : HOME_PATH}
        passHref
      >
        <StyledLogoContainer className="logoContainer">
          <Image
            src={`/images/HeaderLogo/${img}.svg`}
            layout="responsive"
            width={width}
            height="37"
          />
        </StyledLogoContainer>
      </Link>
    </StyledLogo>
  );
};

export const Logo = observer(LogoPure);

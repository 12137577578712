import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import HeaderNavbar from '@/components/Navbar/HeaderNavbar/HeaderNavbar';
import AuthStore from '@/stores/AuthStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import OrgStore from '@/stores/OrgStore';
import { useTheme } from '@mui/material';
import { THeaderNavbarItem } from '@/components/Navbar/HeaderNavbar/HeaderNavbarItem';
import { useCartContext } from '@/services/shopify';

import { getNavItems } from '../PageHeader/helpers';
import { TabBar, TabBarContainer } from './PageMobileTabBar.styles';

type Props = {
  setIsSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
};

const PageMobileTabBar = ({ setIsSearchFocused }: Props) => {
  const theme = useTheme();
  const { totalQuantity } = useCartContext();

  const { currentVillageId } = FridgeDoorStore;
  const { isLoggedIn } = AuthStore;
  const { isAdminOrOwnerUser } = OrgStore;

  const toggleSearch = useCallback(() => setIsSearchFocused((prev) => !prev), [
    setIsSearchFocused,
  ]);

  const menuItems: THeaderNavbarItem[] = useMemo(() => {
    return getNavItems({
      mobileTabbar: true,
      isLoggedIn,
      isAdminOrOwnerUser,
      currentVillageId,
      theme,
      totalQuantity,
      toggleSearch,
    });
  }, [
    isLoggedIn,
    isAdminOrOwnerUser,
    currentVillageId,
    theme,
    totalQuantity,
    toggleSearch,
  ]);

  return (
    <TabBar>
      <TabBarContainer>
        <HeaderNavbar items={menuItems} mobileTabbar />
      </TabBarContainer>
    </TabBar>
  );
};

export default observer(PageMobileTabBar);

import { Divider, PopoverOrigin } from '@mui/material';
import { useState, MouseEvent, useMemo, ReactNode } from 'react';
import Link from 'next/link';
import { Button, Menu, MenuItem } from './DropdownMenu.styles';

export type DropdownMenuItemType = {
  node: ReactNode;
  onClick?: () => void;
  path?: string;
};
export type DropdownMenuPropTypes = {
  items: DropdownMenuItemType[];
  buttonComponent: ReactNode;
  onToggleOpen?(open: boolean): void;
  className?: string;
  minWidth?: number;
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
};

export function DropdownMenu({
  items,
  buttonComponent,
  onToggleOpen,
  className,
  minWidth,
  anchorOrigin,
  transformOrigin,
}: DropdownMenuPropTypes): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    onToggleOpen?.(true);
  }

  function handleClose() {
    setAnchorEl(null);
    onToggleOpen?.(false);
  }

  const keys = useMemo(() => {
    return new Array(items?.length).keys();
  }, [items]);
  return (
    <div className={className}>
      <Button onClick={handleClick} type="button">
        {buttonComponent}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth, borderRadius: 16 },
          variant: 'outlined',
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {items?.map((item, index) => {
          if (item.node === 'divider') {
            return <Divider key={keys[index]} />;
          }
          const menuItem = (
            <MenuItem
              component="a"
              key={keys[index]}
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
                handleClose();
              }}
            >
              {item.node}
            </MenuItem>
          );

          return item.path ? (
            <Link href={item.path}>{menuItem}</Link>
          ) : (
            menuItem
          );
        })}
      </Menu>
    </div>
  );
}

DropdownMenu.defaultProps = {
  transformOrigin: { horizontal: 'left', vertical: 'top' },
} as Partial<DropdownMenuPropTypes>;

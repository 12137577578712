import { cmsProxyApi } from '@/services/cmsProxyApi';
import * as Sentry from '@sentry/nextjs';
import { ICustomPageRes } from '@/types/PageBuilder/PageBuilder';
import cacheService from './cacheService';

const customPageService = {
  async getPage(slug: string): Promise<ICustomPageRes | null> {
    try {
      const { data } = await cmsProxyApi.get<ICustomPageRes>(
        `/customPage/${slug}`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getPages(): Promise<ICustomPageRes[] | null> {
    try {
      const { data } = await cacheService.getOrUpdate(
        'customPages',
        async () => cmsProxyApi.get<ICustomPageRes[]>('/customPage'),
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }
};

export default customPageService;

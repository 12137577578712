import { ChangeEvent, useMemo, useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import xw from 'xwind';
import { Grid, Popper, PopperProps } from '@mui/material';

import { ProductTypes } from '@/common/constants/ProductTypes';
import { ContentType } from '@/common/constants/ContentTypes';

import { useIntercomHeight } from '@/hooks/useIntercomHeight';

import { SearchResultType, searchService } from '@/services/searchService';
import { Logo } from '@/components/Logo';
import { Autocomplete } from '@/components/AutoComplete/Autocomplete';
import AuthStore from '@/stores/AuthStore';
import TutorialStore, { getTutorialStepId } from '@/stores/TutorialStore';
import { HOME_PATH } from '@/common/constants/routes';
import Icon from '@/components/icons/Icon';
import {
  SearchWrapper,
  DropdownWrapper,
  Header,
  HeaderContainer,
  LogoWrapper,
  IconMenuButton,
  PageBackdrop,
} from './PageHeader.styles';
import ProfileDropdown from './UserSection/ProfileDropdown';

const NoSSRDropdownWrapper = dynamic(
  () => import('./PageHeader.styles').then((mod) => mod.DropdownWrapper),
  { ssr: false },
) as typeof DropdownWrapper;

function RawPageHeader(): JSX.Element {
  const { isLoggedIn } = AuthStore;
  const { isTutorialActive, isOnboardingFinished } = TutorialStore;

  const intercomHeight = useIntercomHeight();
  const router = useRouter();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResult, setSearchResult] = useState<Array<SearchResultType>>([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const isHome = router.pathname === HOME_PATH ? 1 : 0;

  const searchPopperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [-5, -20],
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (isTutorialActive && isOnboardingFinished) {
      TutorialStore.fetchTutorial();
    }
  }, [isTutorialActive, isOnboardingFinished]);

  function handleOptionSelected(option: SearchResultType) {
    if (option?.type === ProductTypes.AFFILIATE) {
      router.push(`/${ContentType.PRODUCTS}/${option.slug}`);
      return;
    }
    router.push(`/${option.type}/${option.slug}`);
  }

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target?.value;
    if (!search) {
      setSearchResult([]);
    } else if (search?.trim()) {
      setLoadingSearch(true);
      const data = await searchService.searchBy(search);
      if (data?.result) {
        setSearchResult(data?.result);
      }
      setLoadingSearch(false);
    }
  };

  const handleSearchFocus = (e) => {
    e.stopPropagation();
    setIsSearchFocused(true);
  };

  const handleSearchBlur = (e) => {
    e.stopPropagation();
    setIsSearchFocused(false);
  };

  const handleChange = (e, option: SearchResultType) => {
    if (!option) return;
    handleOptionSelected(option);
    handleSearchBlur(e);
  };

  const getSearchPopperAnchorEl = useCallback(() => {
    const anchor = document.getElementById('header-search');

    if (anchor) {
      anchor.classList.add('focused');

      return anchor;
    }

    return null;
  }, []);

  return (
    <Header top={intercomHeight}>
      <HeaderContainer id={getTutorialStepId(5)} sx={xw`flex-nowrap`}>
        <LogoWrapper id={getTutorialStepId(0)}>
          <Logo />
        </LogoWrapper>
        {router.pathname !== HOME_PATH && (
          <>
            <SearchWrapper
              id="header-search"
              className={isSearchFocused && 'focused'}
              onClick={handleSearchFocus}
              sx={xw`max-w-lg mx-auto`}
            >
              <IconMenuButton
                variant="text"
                color="inherit"
                onClick={handleSearchFocus}
              >
                <Icon iconName="search" />
              </IconMenuButton>
              <Autocomplete
                options={searchResult}
                loading={loadingSearch}
                label="Search OneVillage"
                noOptionsText="Try searching for groups, discussions, products, etc."
                getOptionLabel={(option: SearchResultType) => option.title}
                isFocused={isSearchFocused}
                PopperComponent={(props: Omit<PopperProps, 'css'>) => (
                  <Popper
                    {...props}
                    anchorEl={getSearchPopperAnchorEl}
                    modifiers={searchPopperModifiers}
                  />
                )}
                onSearch={handleSearch}
                onChange={handleChange}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
              />
            </SearchWrapper>
            <PageBackdrop open={isSearchFocused} onClick={handleSearchBlur} />
          </>
        )}
        <NoSSRDropdownWrapper
          authorized={isLoggedIn ? 1 : 0}
          sx={xw`flex-none mt-0`}
        >
          {isLoggedIn && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <ProfileDropdown />
              </Grid>
            </Grid>
          )}
        </NoSSRDropdownWrapper>
      </HeaderContainer>
    </Header>
  );
}

export const PageHeaderCompany = observer(RawPageHeader);

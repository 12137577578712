import {
  DISCOVER_PATH,
  FEED_PATH,
  HOME_PATH,
  ORG_PATH,
  SHOP_PATH,
  VILLAGE_PATH,
} from '@/common/constants/routes';
import { THeaderNavbarItem } from '@/components/Navbar/HeaderNavbar/HeaderNavbarItem';
import { getTutorialStepId } from '@/stores/TutorialStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { Counter } from './PageHeader.styles';

type GetNavItemsProps = {
  isLoggedIn: boolean;
  isAdminOrOwnerUser?: boolean;
  mobileTabbar?: boolean;
  currentVillageId: string;
  theme: any;
  totalQuantity: number;
  toggleSearch?: () => void;
};
export const getNavItems = ({
  mobileTabbar = false,
  isLoggedIn,
  currentVillageId,
  theme,
  totalQuantity,
  toggleSearch,
  isAdminOrOwnerUser,
}: GetNavItemsProps): THeaderNavbarItem[] => {
  const flex1El = {
    sx: { flex: 1 },
    emptyBox: true,
  };

  const getVillagePath = (path = '') =>
    currentVillageId ? `${VILLAGE_PATH}/${currentVillageId}${path}` : HOME_PATH;

  const result: THeaderNavbarItem[] = [];

  if (!mobileTabbar) result.push(flex1El);

  if (mobileTabbar && isLoggedIn) {
    if (toggleSearch) {
      result.push({
        iconName: 'search',
        title: 'Search',
        label: 'Search',
        onClick: toggleSearch,
      });
    }
  }
  result.push({
    iconName: 'home',
    title: 'Home',
    label: 'Home',
    path: getVillagePath('/home'),
    strictPath: true,
    visible: !!currentVillageId,
  });

  // if (isLoggedIn) {
  //   result.push({
  //     path: currentVillageId ? `${FEED_PATH}/${currentVillageId}` : HOME_PATH,
  //     iconName: 'feed',
  //     strictPath: false,
  //     surveyNeeded: false,
  //     title: 'Feed',
  //     label: 'Feed',
  //     visible: !!currentVillageId,
  //   });
  // }

  result.push({
    path: DISCOVER_PATH,
    activeForPaths:
      mobileTabbar && !isLoggedIn
        ? [DISCOVER_PATH]
        : [
            DISCOVER_PATH,
            currentVillageId ? `${FEED_PATH}/${currentVillageId}` : HOME_PATH,
          ],
    iconName: 'community',
    strictPath: false,
    surveyNeeded: false,
    title: 'Discover',
    label: 'Community',
    id: getTutorialStepId(1),
  });

  if (FridgeDoorStore.isAdmin) {
    result.push({
      path: getVillagePath('/villagers'),
      iconName: 'users',
      surveyNeeded: false,
      strictPath: false,
      title: 'Family Members',
      label: 'Family Members',
    });
  }

  // result.push({
  //   path: SHOP_PATH,
  //   iconName: 'shop',
  //   surveyNeeded: false,
  //   strictPath: false,
  //   title: 'Shop',
  //   label: 'Shop',
  //   id: getTutorialStepId(2),
  // });

  const orgItem: THeaderNavbarItem = {
    path: ORG_PATH,
    iconName: 'org',
    surveyNeeded: false,
    strictPath: false,
    title: 'Organization',
    label: 'Organization',
  };
  if (mobileTabbar && isAdminOrOwnerUser) result.push(orgItem);

  if (!mobileTabbar) {
    // result.push({
    //   iconName: 'wishlist',
    //   path: getVillagePath('/wishlist'),
    //   surveyNeeded: true,
    //   strictPath: true,
    //   title: 'Wishlist',
    //   label: 'Wishlist',
    //   visible: isLoggedIn && !!currentVillageId,
    //   id: getTutorialStepId(4),
    // });

    if (isAdminOrOwnerUser) result.push(orgItem);

    result.push(flex1El, {
      iconName: 'shopping-cart',
      path: '/cart',
      surveyNeeded: false,
      strictPath: true,
      title: 'Cart',
      label: '',
      visible: isLoggedIn && !!currentVillageId,
      sx: { marginRight: `${theme.spacing(4)} !important` },
      children: (
        <Counter
          color="error"
          badgeContent={totalQuantity}
          invisible={!totalQuantity}
        />
      ),
    });
  }

  return result;
};

const FIVE_MINUTES = 5 * 60 * 1000;

const cacheService = {
  async getOrUpdate(
    key: string,
    update: () => Promise<any>,
    ttl: number = FIVE_MINUTES,
  ): Promise<any> {
    const cachedData = localStorage?.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (timestamp + ttl > Date.now()) {
        return Promise.resolve(data);
      }
    }
    const data = await update();
    localStorage?.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
    return data;
  },
};

export default cacheService;

import { useEffect, useState } from 'react';
import Link from 'next/link';
import xw from 'xwind';
import { Grid, Stack } from '@mui/material';

import customPageService from '@/services/customPageService';
import Container from '../../components/layout/Container';
import {
  FooterDivider,
  FooterInfoText,
  MenuContainer,
  MenuItem,
  SocialMediaContainer,
} from './PageFooter.styles';
import SocialLink from './SocialLink';

function PageFooter({
  children,
  bottomChild,
}: {
  children?: JSX.Element;
  bottomChild?: JSX.Element;
}): JSX.Element {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    (async function () {
      const forEmployersPage = await customPageService.getPages();
      setLinks(forEmployersPage);
    })();
  }, []);

  return (
    <footer id="footer" css={xw`w-full bg-primary pt-8 pb-6`}>
      <Container>
        {children}
        <Grid container justifyContent="space-between" spacing={2.5}>
          <Grid item xs={12}>
            <MenuContainer>
              <Link href="/medical-review-board" passHref>
                <MenuItem>Medical Review Board</MenuItem>
              </Link>
              {links?.map((page) => (
                <Link key={page._id} href={`/c/${page.slug.current}`} passHref>
                  <MenuItem>{page.title}</MenuItem>
                </Link>
              ))}
            </MenuContainer>
          </Grid>
          <Grid item xs={6} md={9}>
            <MenuContainer>
              <Link href="/contact-us" passHref>
                <MenuItem>Contact Us</MenuItem>
              </Link>
              <Link href="/for-employers" passHref>
                <MenuItem>For Employers</MenuItem>
              </Link>
              <Link href="/privacy-policy" passHref>
                <MenuItem>Privacy Policy</MenuItem>
              </Link>
              <Link href="/terms-and-conditions" passHref>
                <MenuItem>Terms & Conditions</MenuItem>
              </Link>
            </MenuContainer>
          </Grid>
          <Grid item xs={6} md={3}>
            <SocialMediaContainer>
              <SocialLink
                href="https://www.linkedin.com/company/myonevillage"
                src="/icons/linkedin.svg"
                alt="linkedin"
              />
              <SocialLink
                href="https://www.facebook.com/getonevillage"
                src="/icons/facebook.svg"
                alt="facebook"
              />
              <SocialLink
                href="https://www.instagram.com/myonevillage/"
                src="/icons/instagram.svg"
                alt="instagram"
              />
              <SocialLink
                href="https://twitter.com/myonevillage"
                src="/icons/twitter.svg"
                alt="twitter"
              />
              <SocialLink
                href="https://www.pinterest.com/MyOneVillage/"
                src="/icons/pinterest.png"
                alt="pinterest"
              />
            </SocialMediaContainer>
          </Grid>
          <Grid item xs={12}>
            {/* <FooterDivider /> */}
          </Grid>
          <Grid item xs={12}>
            <Stack />
          </Grid>
        </Grid>
        {bottomChild}
      </Container>
    </footer>
  );
}

export default PageFooter;

import React, { useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material';

import FridgeDoorStore from '@/stores/FridgeDoorStore';
import NotificationsStore from '@/stores/NotificationsStore';

import {
  VillageButtonCurrent,
  VillageButtonGroup,
} from './NavbarVillageLink.styles';
import NavbarVillageLinkMenu from './NavbarVillageLinkMenu';
import { CaretButton, VillageIcon, VillageName } from './helpersUI';

const NavbarVillageLink = (): JSX.Element => {
  const theme = useTheme();
  const { asPath } = useRouter();

  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const {
    currentVillageId,
    currentVillage,
    currentVillagePath,
  } = FridgeDoorStore;
  const { totalUnread } = NotificationsStore;

  const hasVillage = useMemo(() => {
    return currentVillage || currentVillageId;
  }, [currentVillage, currentVillageId]);

  const isCurrentVillagePages = useMemo(
    () => asPath.startsWith(currentVillagePath),
    [asPath, currentVillagePath],
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <VillageButtonGroup variant="contained" ref={anchorRef} active={open}>
        <Link href={hasVillage ? `${currentVillagePath}/home` : undefined}>
          <VillageButtonCurrent
            color={isCurrentVillagePages ? 'warning' : 'info'}
            startIcon={
              <VillageIcon
                currentVillageFirstName={currentVillage?.first_name}
                totalUnread={totalUnread}
              />
            }
            sx={{ minWidth: { sm: theme.spacing(45) } }}
          >
            <VillageName
              currentVillageFirstName={`${
                currentVillage?.first_name?.trim() || '?'
              } ${currentVillage?.subscription_info?.user?.last_name || ''}`}
            />
          </VillageButtonCurrent>
        </Link>
        <CaretButton
          isCurrentVillagePages={isCurrentVillagePages}
          onToggle={handleToggle}
        />
      </VillageButtonGroup>

      <NavbarVillageLinkMenu
        open={open}
        anchorRef={anchorRef}
        onClose={handleClose}
        onToggle={handleToggle}
        isCurrentVillagePages={isCurrentVillagePages}
      />
    </>
  );
};

export default observer(NavbarVillageLink);

import { ContentType } from '@/common/constants/ContentTypes';
import { ProductTypes } from '@/common/constants/ProductTypes';
import { captureException } from '@sentry/nextjs';
import { cmsProxyApi } from './cmsProxyApi';

export const searchService = {
  async searchBy(searchTerm: string): Promise<HTTPResponse> {
    try {
      const criteria = encodeURI(searchTerm);
      const { data } = await cmsProxyApi.get('/search', {
        params: { criteria },
      });
      return data;
    } catch (error) {
      captureException(error);
      return null;
    }
  },
};

export type SearchResultType = {
  type: ContentType | ProductTypes;
  title: string;
  slug: string;
};

type HTTPResponse = {
  total: number;
  searchParam: string;
  result: Array<SearchResultType>;
};

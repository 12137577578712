import { useEffect, useState } from 'react';
import getPublicConfig from '@/utils/getPublicConfig';

const publicRuntimeConfig = getPublicConfig();
const { FACEBOOK_APP_ID } = publicRuntimeConfig;

function useFacebookSDK(): any {
  const [isFacebookSDKLoaded, setIsFacebookSDKLoaded] = useState(false);
  const [facebookSDK, setFacebookSDK] = useState(null);

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      });

      setFacebookSDK(window?.FB);
      setIsFacebookSDKLoaded(true);
    };
  };

  const loadSDKAsync = () => {
    // eslint-disable-next-line func-names
    (function () {
      const id = 'facebook-jssdk';
      const fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById(id)) {
        return;
      }
      const js = document.createElement('script') as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })();
  };

  const createFbRoot = () => {
    let fbRoot = document.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';
      document.body.appendChild(fbRoot);
    }
  };

  useEffect(() => {
    if (document.getElementById('facebook-jssdk') && window?.FB) {
      setIsFacebookSDKLoaded(true);
      setFacebookSDK(window?.FB);

      return;
    }

    setFbAsyncInit();
    loadSDKAsync();
    createFbRoot();
  }, []);

  return { isFacebookSDKLoaded, facebookSDK };
}

export default useFacebookSDK;

import { ChangeEvent, useMemo, useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import xw from 'xwind';
import {
  Button as MuiButton,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Popper,
  PopperProps,
} from '@mui/material';

import { ProductTypes } from '@/common/constants/ProductTypes';
import { ContentType } from '@/common/constants/ContentTypes';

import { useIntercomHeight } from '@/hooks/useIntercomHeight';

import { SearchResultType, searchService } from '@/services/searchService';
import { useCartContext } from '@/services/shopify';

import { Autocomplete } from '@/components/AutoComplete/Autocomplete';
import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';
import TutorialStore, { getTutorialStepId } from '@/stores/TutorialStore';
import useHasMounted from '@/hooks/useHasMounted';
import HeaderNavbar from '@/components/Navbar/HeaderNavbar/HeaderNavbar';
import { THeaderNavbarItem } from '@/components/Navbar/HeaderNavbar/HeaderNavbarItem';
import NavbarVillageLink from '@/components/Navbar/NavbarVillageLink/NavbarVillageLink';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import ModalStore from '@/stores/ModalStore';
import { ModalTypes } from '@/domains/Modals/ModalTypes';
// import Tutorial from '@/domains/Tutorial';
import Icon from '@/components/icons/Icon';
import { Logo } from '@/components/Logo';
import OrgStore from '@/stores/OrgStore';
import {
  SearchWrapper,
  DropdownWrapperSimple,
  Header,
  HeaderContainer,
  LogoWrapper,
  IconMenuButton,
  NavbarDrawer,
  JoinButton,
  PageBackdrop,
} from './PageHeader.styles';
import { HelpHeader } from './HelpHeader';
import { getNavItems } from './helpers';

const NoSSRDropdownWrapper = dynamic(
  () => import('./PageHeader.styles').then((mod) => mod.DropdownWrapperSimple),
  { ssr: false },
) as typeof DropdownWrapperSimple;

type Props = {
  isSearchFocused?: boolean;
  setIsSearchFocused?: React.Dispatch<React.SetStateAction<boolean>>;
  hideContent?: boolean;
};
function RawPageHeader({
  isSearchFocused: _isSearchFocused,
  setIsSearchFocused: _setIsSearchFocused,
  hideContent = false,
}: Props): JSX.Element {
  const { isOpen, closeModal, currentModal } = ModalStore;
  const { isLoggedIn } = AuthStore;
  const { isAdminOrOwnerUser } = OrgStore;
  const { currentVillageId } = FridgeDoorStore;

  const router = useRouter();
  const theme = useTheme();
  const matchesLgMediaQuery = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXlMediaQuery = useMediaQuery(theme.breakpoints.up('xl'));
  const intercomHeight = useIntercomHeight();
  const { totalQuantity } = useCartContext();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResult, setSearchResult] = useState<Array<SearchResultType>>([]);
  const [isSearchFocusedLocal, setIsSearchFocusedLocal] = useState(false);

  const isSearchFocused = _isSearchFocused ?? isSearchFocusedLocal;
  const setIsSearchFocused = _setIsSearchFocused ?? setIsSearchFocusedLocal;

  const hasMounted = useHasMounted();

  const headerNav: THeaderNavbarItem[] = useMemo(() => {
    const items = getNavItems({
      isLoggedIn,
      currentVillageId,
      theme,
      totalQuantity,
      isAdminOrOwnerUser,
    });
    return matchesLgMediaQuery ? items : items.slice(-2);
  }, [
    isLoggedIn,
    currentVillageId,
    theme,
    totalQuantity,
    isAdminOrOwnerUser,
    matchesLgMediaQuery,
  ]);

  const searchPopperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [-5, -20],
        },
      },
    ],
    [],
  );

  // useEffect(() => {
  //   if (isTutorialActive && isOnboardingFinished) {
  //     TutorialStore.fetchTutorial();
  //   }
  // }, [isTutorialActive, isOnboardingFinished]);

  function handleOptionSelected(option: SearchResultType) {
    if (option?.type === ProductTypes.AFFILIATE) {
      router.push(`/${ContentType.PRODUCTS}/${option.slug}`);
      return;
    }
    router.push(`/${option.type}/${option.slug}`);
  }

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target?.value;
    if (!search) {
      setSearchResult([]);
    } else if (search?.trim()) {
      setLoadingSearch(true);
      const data = await searchService.searchBy(search);
      if (data?.result) {
        setSearchResult(data?.result);
      }
      setLoadingSearch(false);
    }
  };

  const handleSearchFocus = (e) => {
    e.stopPropagation();
    setIsSearchFocused(true);
  };

  const handleSearchBlur = (e) => {
    e.stopPropagation();
    setIsSearchFocused(false);
  };

  const handleChange = (e, option: SearchResultType) => {
    if (!option) return;
    handleOptionSelected(option);
    handleSearchBlur(e);
  };

  const getSearchPopperAnchorEl = useCallback(() => {
    const anchor = document.getElementById('header-search');

    if (anchor) {
      anchor.classList.add('focused');

      return anchor;
    }

    return null;
  }, []);

  if (!hasMounted) return null;

  return (
    <>
      <Box>
        <HelpHeader />
      </Box>

      <Header top={intercomHeight}>
        <HeaderContainer id={getTutorialStepId(5)}>
          <LogoWrapper id={getTutorialStepId(0)}>
            <Logo />
          </LogoWrapper>

          {!hideContent && isLoggedIn && (
            <>
              {matchesLgMediaQuery && (
                <IconMenuButton
                  variant="text"
                  color="inherit"
                  onClick={handleSearchFocus}
                >
                  <Icon iconName="search" />
                </IconMenuButton>
              )}
              <SearchWrapper
                id="header-search"
                className={!isSearchFocused && 'hidden'}
                onClick={handleSearchFocus}
              >
                <Autocomplete
                  options={searchResult}
                  loading={loadingSearch}
                  label="Search OneVillage"
                  noOptionsText="Try searching for groups, discussions, products, etc."
                  getOptionLabel={(option: SearchResultType) => option.title}
                  isFocused={isSearchFocused}
                  PopperComponent={(props: Omit<PopperProps, 'css'>) => (
                    <Popper
                      {...props}
                      anchorEl={getSearchPopperAnchorEl}
                      modifiers={searchPopperModifiers}
                    />
                  )}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  onFocus={handleSearchFocus}
                  onBlur={handleSearchBlur}
                />
              </SearchWrapper>
              <PageBackdrop open={isSearchFocused} onClick={handleSearchBlur} />
            </>
          )}
          {!hideContent && (
            <>
              <HeaderNavbar items={headerNav} />
              <NoSSRDropdownWrapper
                spacing={{ xs: 1, sm: 5, md: 7 }}
                direction="row"
              >
                {isLoggedIn ? (
                  <NavbarVillageLink />
                ) : (
                  <>
                    <MuiButton
                      variant="outlined"
                      onClick={() => AuthModalStore.showModal('login')}
                      sx={{
                        px: { xs: theme.spacing(2), sm: theme.spacing(4) },
                      }}
                    >
                      Sign In
                    </MuiButton>
                    <JoinButton
                      variant="contained"
                      onClick={() => AuthModalStore.showModal('signup')}
                    >
                      Join Now
                    </JoinButton>
                  </>
                )}
              </NoSSRDropdownWrapper>
            </>
          )}
        </HeaderContainer>
        {!hideContent && !matchesXlMediaQuery && (
          <NavbarDrawer
            anchor="top"
            open={isOpen && currentModal === ModalTypes.MobileDrawer}
            onClose={closeModal}
          >
            <Stack
              component={HeaderContainer}
              direction="row"
              justifyContent="flex-end"
            >
              <IconMenuButton
                variant="text"
                color="inherit"
                css={xw`min-w-0`}
                onClick={closeModal}
              >
                <Icon iconName="times" />
              </IconMenuButton>
            </Stack>
            <HeaderNavbar
              items={headerNav}
              direction="column"
              spacing={3}
              onItemClick={closeModal}
              css={xw`pb-16`}
            />
          </NavbarDrawer>
        )}
        {/* <Tutorial /> */}
      </Header>
    </>
  );
}

export const PageHeader = observer(RawPageHeader);

import { Badge } from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Anchor = styled.a(
  css`
    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  `,
);

export const UnreadIndicator = styled(Badge)`
  .MuiBadge-badge {
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }
`;

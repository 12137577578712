import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  styled,
} from '@mui/material';
import { alpha } from '@mui/system';

export const Menu = styled(MuiMenu)``;

export const MenuItem = styled(MuiMenuItem)<{ component?: string }>`
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary[300], 0.5)};
    color: ${({ theme }) => theme.palette.primary[500]};
  }
`;

export const Button = styled('button')`
  height: 100%;
`;

import { StyledComponentWithProps } from '@/types/StyledComponentWithProps';
import {
  Paper,
  TextField,
  styled,
  Stack,
  InputAdornment,
  Theme,
} from '@mui/material';
import { alpha } from '@mui/system';
import { FC, forwardRef } from 'react';

export const MuiPaper = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

export const Popper: FC = forwardRef<HTMLDivElement>((props) => {
  return <MuiPaper {...props} variant="outlined" />;
}) as FC;

export const MuiStack = styled(Stack)<{ hasManyOptions: boolean }>`
  &.MuiAutocomplete-listbox {
    padding: 0;
    .MuiAutocomplete-option {
      min-height: unset;
      font-size: ${({ theme }) => theme.spacing(4)};
      line-height: 1;
      &:first-of-type,
      &:last-of-type {
        height: ${({ theme }) => theme.spacing(10)};
      }
      &:first-of-type {
        align-items: ${({ hasManyOptions }) => hasManyOptions && 'flex-end'};
      }
      &:last-of-type {
        align-items: ${({ hasManyOptions }) => hasManyOptions && 'flex-start'};
      }
      &.Mui-focusVisible,
      &.Mui-focused {
        background-color: ${({ theme }) =>
          alpha(theme.palette.primary[500], 0.3)};
      }
    }
  }
`;

export const List: FC = forwardRef<HTMLUListElement>((props, ref) => {
  return (
    <MuiStack
      as="ul"
      ref={ref}
      {...props}
      hasManyOptions={(props?.children as Array<any>)?.length > 1}
    />
  );
}) as FC;

export function getBoxShadow({ theme }: { theme: Theme }): string {
  return `${theme.spacing(0, 0, 1, 0)} ${alpha(theme.palette.grey[200], 0.5)}`;
}

export const InputComponent: StyledComponentWithProps<{
  open: boolean;
}> = styled(TextField)<{ open: boolean }>`
  position: relative;
  z-index: 1;
  .MuiInputLabel-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ theme }) => `calc(100% - ${theme.spacing(12)})`};
    display: block;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      display: none;
    }
    color: ${({ theme }) => theme.palette.grey[350]};
    font-size: ${({ theme }) => theme.spacing(4)};

    position: absolute;
    top: ${({ theme }) => theme.spacing(-1.2)};
    left: ${({ theme }) => theme.spacing(8)};
  }

  .MuiOutlinedInput-root.MuiInputBase-root {
    height: ${({ theme }) => theme.spacing(11)};
    padding: 0;
    padding-left: ${({ theme }) => theme.spacing(4)};
    color: ${({ theme }) => theme.palette.grey[600]};
    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
      background-color: ${({ theme }) => theme.palette.common.white};
      border-color: ${({ theme }) => alpha(theme.palette.grey[350], 0.5)};
      border-radius: ${({ theme }) => theme.spacing(50)};
      z-index: -1;
    }
    .MuiOutlinedInput-input {
      height: auto;
      padding: 0;
      font-size: ${({ theme }) => theme.spacing(4)};
      line-height: 1;
    }
    .MuiAutocomplete-endAdornment {
      top: 0;
      bottom: 0;
    }
    & input[type='search']::-webkit-search-cancel-button {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    .MuiInputLabel-root {
      font-size: ${({ theme }) => theme.spacing(4)};
      line-height: 1.4375em;
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        height: auto;
        padding: 0;
        font-size: ${({ theme }) => theme.spacing(4)};
        line-height: 1;
      }
    }
  }
`;

export const Adornment = styled(InputAdornment)`
  color: ${({ theme }) => theme.palette.grey[350]};
`;

export const LoadingComponent = styled('span')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  line-height: 1;
  font-size: ${({ theme }) => theme.spacing(4)};
`;

import {
  Button,
  ButtonGroup,
  styled,
  ButtonGroupProps,
  MenuItem,
  MenuItemProps,
  Popover,
  Badge,
  Paper,
  Stack,
} from '@mui/material';

export const VillageButtonGroup = styled(ButtonGroup)<
  ButtonGroupProps & { active?: boolean }
>`
  box-shadow: none;
  .MuiButtonGroup-grouped {
    min-width: ${({ theme }) => theme.spacing(6.5)};
    ${({ active }) =>
      active && 'border-bottom-left-radius: 0; border-bottom-right-radius: 0;'}

    &:not(:last-of-type) {
      border-color: ${({ theme }) => theme.palette.divider};
      padding-right: ${({ theme }) => theme.spacing(2)};
    }
  }
  position: relative;
  z-index: 5;
`;

export const IconButton = (styled(Button)`
  font-size: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(3)};
` as unknown) as typeof Button;

export const VillageButtonCurrent = styled(Button)(({ theme, color }) => {
  const styles: Record<string, any> = {
    padding: theme.spacing(1.5),
    textTransform: 'none',
  };

  if (color === 'info') {
    styles.color = theme.palette.common.black;
  }
  return styles;
});

export const NewVillageMenuItem = styled(MenuItem)<
  MenuItemProps & { component: string }
>`
  border: 2px solid ${({ theme }) => theme.palette.primary[300]};
  color: ${({ theme }) => theme.palette.primary.dark};
  padding-left: 0;
  padding-right: 0;
  margin: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)}
    ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: ${({ theme }) => theme.spacing(3)}
      ${({ theme }) => theme.spacing(6)};
  }

  border-radius: ${({ theme }) => theme.spacing(5)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: ;
  max-width: ${({ theme }) => theme.spacing(82)};
`;

export const NotificationsPopover = styled(Popover)`
  .MuiPopover-paper {
    max-height: 90%;
    display: flex;
    flex-direction: column;
    width: 85vw;
    max-width: 580px;
  }
`;

export const Counter = styled(Badge)`
  .MuiBadge-badge {
    min-width: 18px;
    height: 18px;
    padding: 0 3px;
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 700;
    position: relative;
    transform: none;
  }
`;

export const UnreadCircle = styled('div')`
  position: absolute;
  top: -1px;
  right: -1px;
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background-color: ${({ theme }) => theme.palette.error.main};
  border: 1px solid ${({ theme }) => theme.palette.common.white};
`;

export const MenuPaper = styled(Paper)<{ isMobileView: boolean }>`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.grey[50]};
    border-radius: ${({ theme, isMobileView }) =>
      isMobileView ? 0 : `${theme.shape.borderRadius}px`};

    width: 100vw;
    height: 100vh;
  }
`;

export const MobileMenuHeaderContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  min-height: ${({ theme }) => theme.spacing(14)};
  padding: 0 ${({ theme }) => theme.spacing(6)};
`;

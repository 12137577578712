import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DropdownMenu } from '@/components/DropdownMenu/DropdownMenu';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import AuthStore from '@/stores/AuthStore';

import useAuth from '@/hooks/useAuth';

import { HOME_PATH } from '@/common/constants/routes';
import { ProfileTitle } from './ProfileTitle';

function ProfileDropdown(): JSX.Element {
  const { logout } = useAuth();
  const { cmsUser, isOrganizationMember } = AuthStore;
  const { currentVillageId } = FridgeDoorStore;
  const [isOpen, setIsOpen] = useState(false);

  const items = useMemo(() => {
    const userFeed = {
      node: 'Your Feed',
      path: currentVillageId ? `/u/${currentVillageId}` : HOME_PATH,
    };

    const settings = {
      node: 'My Profile',
      path: '/settings/notifications',
    };

    const logoutBtn = {
      node: 'Log Out',
      onClick: logout,
    };

    return isOrganizationMember ? [logoutBtn] : [userFeed, settings, logoutBtn];
  }, [currentVillageId, isOrganizationMember, logout]);

  return (
    <DropdownMenu
      minWidth={170}
      onToggleOpen={setIsOpen}
      buttonComponent={<ProfileTitle cmsUser={cmsUser} isOpen={isOpen} />}
      items={items}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}

export default observer(ProfileDropdown);

import React from 'react';
import xw from 'xwind';
import { observer } from 'mobx-react-lite';
import { Stack, StackProps } from '@mui/material';
import HeaderNavbarItem, {
  THeaderNavbarItem,
} from '@/components/Navbar/HeaderNavbar/HeaderNavbarItem';
import AuthStore from '@/stores/AuthStore';
import OrgStore from '@/stores/OrgStore';

const HeaderNavbar = ({
  items,
  mobileTabbar = false,
  collapsed,
  onItemClick,
  ...props
}: StackProps & {
  items: THeaderNavbarItem[];
  mobileTabbar?: boolean;
  collapsed?: boolean;
  onItemClick?: () => void;
}): JSX.Element => {
  const { sx: sxProps, direction = 'row', ...restProps } = props;
  const { isLoggedIn } = AuthStore;
  const { isAdminOrOwnerUser } = OrgStore;

  return (
    <Stack
      direction={direction}
      spacing={
        mobileTabbar ? 0 : isAdminOrOwnerUser ? 0.5 : isLoggedIn ? 1.5 : 7.5
      }
      component="nav"
      sx={[
        xw`flex items-center transition`,
        mobileTabbar && {
          maxWidth: '28rem',
          justifyContent: isLoggedIn ? 'space-between' : 'space-around',
        },
        mobileTabbar ? xw`py-2 overflow-x-auto` : xw`justify-center`,
        collapsed && xw`opacity-0`,
        sxProps,
        { flex: 1 },
      ]}
      {...restProps}
    >
      {items.map((item) => (
        <HeaderNavbarItem
          key={item.path + item.iconName}
          onClick={onItemClick}
          mobileTabbar={mobileTabbar}
          isAdminOrOwnerUser={isAdminOrOwnerUser}
          {...item}
        />
      ))}
    </Stack>
  );
};

HeaderNavbar.defaultProps = {
  collapsed: false,
  onItemClick: () => {},
};

export default observer(HeaderNavbar);

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import CustomAvatar from '@/components/CustomAvatar';
import { Notification } from '@/types/Notifications';
import { color } from 'theme.material';
import { Anchor, UnreadIndicator } from './Notification.styles';

interface INotificationItemProps {
  notification: Notification;
}

const NotificationItem: React.FC<INotificationItemProps> = ({
  notification,
}): JSX.Element => {
  const {
    url,
    text,
    content_title: contentTitle,
    action_user_name: actionUserName,
    created_at: createdAt,
    read_at: readAt,
  } = notification;

  return (
    <Grid container alignItems="center" wrap="nowrap" mb={4} gap={2}>
      <Grid item>
        <CustomAvatar
          name={actionUserName}
          size={{ desktop: 11, mobile: 11 }}
          fontSize={{ desktop: 6, mobile: 6 }}
          backgroundColor={color.teal[500]}
        />
      </Grid>

      <Grid item flexGrow={1}>
        <Anchor href={url}>
          <Typography
            variant="h5"
            component="span"
            lineHeight={1.5}
            fontWeight={400}
          >
            <Typography
              variant="h5"
              component="span"
              lineHeight={1.5}
              fontWeight={700}
            >
              {actionUserName || 'OneVillage'}
            </Typography>
            {` ${text} `}
            <Typography
              variant="h5"
              component="span"
              lineHeight={1.5}
              fontWeight={700}
            >
              {contentTitle}
            </Typography>
          </Typography>
        </Anchor>
        <Typography
          variant="h5"
          component="span"
          fontWeight={500}
          color="grey.300"
        >
          {` ${format(new Date(createdAt), 'h:mm a')}`}
        </Typography>
      </Grid>

      <Grid item alignSelf="flex-start" pr={4}>
        {!readAt && <UnreadIndicator color="primary" variant="dot" />}
      </Grid>
    </Grid>
  );
};

export default NotificationItem;

import { useRouter } from 'next/router';

import AuthStore from '@/stores/AuthStore';

import { HOME_PATH } from '@/common/constants/routes';
import useFacebookSDK from '@/hooks/useFacebookSDK';
import { useCartContext } from '@/services/shopify';

const useAuth = (): any => {
  const router = useRouter();
  const { isFacebookSDKLoaded, facebookSDK } = useFacebookSDK();
  const checkoutContext = useCartContext();

  const logout = (redirectTo = '') => {
    if (isFacebookSDKLoaded && facebookSDK) {
      facebookSDK.getLoginStatus((response) => {
        if (response?.status === 'connected') {
          facebookSDK.logout();
        }
      });
    }
    AuthStore.logout();
    checkoutContext.reset();
    router.push(redirectTo || HOME_PATH);
  };

  return {
    logout,
  };
};

export default useAuth;

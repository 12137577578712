import CustomAvatar from '@/components/CustomAvatar';
import Icon from '@/components/icons/Icon';
import { Box } from '@mui/material';
import xw from 'xwind';

import { color } from '../../../../theme.material';
import { UnreadCircle, VillageButtonCurrent } from './NavbarVillageLink.styles';

export const VillageIcon = ({
  currentVillageFirstName,
  totalUnread,
}: {
  currentVillageFirstName?: string;
  totalUnread: number;
}) => (
  <div style={{ position: 'relative' }}>
    <CustomAvatar
      name={currentVillageFirstName}
      size={{ desktop: 8, mobile: 8 }}
      fontSize={{ desktop: 4, mobile: 4 }}
      backgroundColor={color.teal[500]}
      bordered
    />
    {totalUnread ? <UnreadCircle /> : null}
  </div>
);

export const VillageName = ({
  currentVillageFirstName,
  mobileView = false,
}: {
  currentVillageFirstName?: string;
  mobileView?: boolean;
}) => (
  <Box
    sx={{
      ...(mobileView
        ? xw`truncate`
        : xw`truncate hidden sm:block lg:hidden xl:block`),
      textAlign: 'left',
      minWidth: mobileView ? 'unset' : '5.7rem',
      lineHeight: 1.2,
    }}
  >
    {currentVillageFirstName || '?'}
  </Box>
);

export const CaretButton = ({
  opened = false,
  isCurrentVillagePages = false,
  onToggle,
}: {
  opened?: boolean;
  isCurrentVillagePages?: boolean;
  onToggle?: () => void;
}) => (
  <VillageButtonCurrent
    color={isCurrentVillagePages ? 'warning' : 'info'}
    onClick={onToggle}
  >
    <Box
      sx={{
        fontSize: 8,
        padding: { xs: '0 0.6rem', sm: '0 0.5rem' },
        transform: opened ? 'rotate(180deg)' : undefined,
      }}
    >
      <Icon iconName="caret-down" />
    </Box>
  </VillageButtonCurrent>
);
